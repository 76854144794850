import React from 'react';
import { graphql } from 'gatsby';
import Blocks from '../components/Blocks';
import Seo from '../components/Seo';

export const query = graphql`
	query pageQuery($_id: String!) {
		page: sanityPage(_id: { eq: $_id }) {
			_id
			title
			seo {
				...seoFields
			}
			_rawBlocks(resolveReferences: { maxDepth: 10 })
			blocks {
				_key
				_type
				_rawContent(resolveReferences: { maxDepth: 10 })
				content {
					...blocks
				}
				blockSettings {
					desktopWidth
				}
			}
		}
	}
`;

const PageTemplate = (props) => {
	const { data } = props;
	const page = data && data.page;
	return (
		<>
			<Seo
				metaTitle={page.seo?.metaTitle || page.title}
				shareTitle={page.seo?.shareTitle || page.title}
				metaDesc={page.seo?.metaDesc}
				shareDesc={page.seo?.shareDesc}
			/>
			<h1 className="text-4xl sm:text-6xl lg:text-8xl mb-8 md:mb-16">
				{page.title}
			</h1>
			<Blocks blocks={page.blocks} _rawBlocks={page._rawBlocks} />
		</>
	);
};

export default PageTemplate;
